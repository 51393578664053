<template>
  <v-app>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4> Zoom users</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Zoom users
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn :loading="sync" @click="__sync()" class="mt-4 btn btn-primary" style="color: #fff">
                    <v-icon small elevation="2" outlined>fas fa-sync</v-icon>&nbsp;
                    Sync zoom user
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
              <div class="">
                <table class="table">
                <thead>
                <tr class="px-3">
                  <th class="px-3" style="max-width: 150px !important; white-space: pre-wrap;">First name</th>
                  <th class="px-3" >Email</th>
                  <th class="px-3" >Status</th>
                  <th class="pr-3 text-center">Action</th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-show="zoom_users.length > 0" v-for="zoom in zoom_users" :key="zoom">
                    <td style="max-width: 150px !important; white-space: pre-wrap;" class="px-3">
                    <span class="text-dark-75  mb-1">
                      {{ `${zoom.first_name} ${zoom.last_name}` }}
                    </span>
                    </td>
                    <td class="px-3">
                      <a  class="text-info font-weight-bolder text-hover-primary mb-1 font-size-lg" :href="'mailto:'+zoom.email">
                        {{zoom.email ? zoom.email : 'NA' }}
                      </a>
                    </td>
                    <td class="px-3">
                          <span class="badge badge-success text-lg`"
                                v-bind:class="{ 'badge-success': zoom.is_active, 'badge-danger': !zoom.is_active }"
                          >{{ zoom.is_active ? 'Active' : 'Inactive' }}</span>
                    </td>
                    <td class="pr-0 text-center">
                      <v-switch @change="deactivateUser(zoom.id)" v-model="zoom.is_active"></v-switch>
                    </td>

                  </tr>
                  <tr v-if="zoom_users.length == 0">
                    <td colspan="4" class="text-center">
                      <strong>
                        Data not available
                      </strong>
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  v-if="zoom_users.length > 0"
                  class="pull-right mt-7"
                  @input="__get"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number

              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import ZoomUserService from "@/core/services/zoom-user/ZoomUserService";

const zoomUserService = new ZoomUserService();
export default {
  name: "ZoomUser",
  data: () => ({
    page: null,
    loading: false,
    sync: false,
    perPage: null,
    total: null,
    zoom_users: [],
    headers: [
      {
        text: 'First Name',
        align: 'start',
        sortable: false,
        value: 'first_name',
      },
      {text: 'Email', value: 'email'},
      {text: 'Action', value: 'action'},

    ],
  }),
  mounted() {
    this.__get();
  },
  methods: {
    __get() {
      this.loading = true;
      zoomUserService.paginate({}, this.page).then(response => {
        this.zoom_users = response.data.data;
        this.page = response.data.meta.current_page;
        this.total = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        this.loading = false;
      })

    },
    __sync() {
      this.sync = true;
      zoomUserService.sync().then(response => {
      }).catch(error => {
        this.$snotify.error("Error while syncing data to zoom");
      }).finally(() => {
        this.$snotify.success("Information updated");
        this.__get();
        this.sync = false;

      })
    },
    deactivateUser(userId) {
      this.$confirm({
        message: 'Are you sure?',
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            zoomUserService.deactivateUser(userId).then(response => {
              this.$snotify.success("Information updated");
            }).catch(error => {
              this.$snotify.error("Something went wrong");
            }).finally(() => {
              this.__get();
            })
          }
        }
      })
    }
  },
}
</script>

<style scoped>

</style>